<template>
    <el-dialog v-loading="loader" :title="title" :visible.sync="visible" width="500px" :before-close="handleClose">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form @submit.prevent="handleSubmit(onSubmit)" ref="form">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="status-text">Active/Inactive</p>
                    <base-switch class="status-toggle super-admin" onText="" offText="" ref="templateSwitcher"
                        v-model="is_active">
                    </base-switch>
                </div>
                <file-input placeholder="Image" name="image"
                    :rules="getImageRules" label="Image" ref="image_input"
                    @change="bannerImageSelect" :label-required="formType == 'store'" :info="`Image dimensions should be min ${adSetInfo.resolution.width || 1000}*${adSetInfo.resolution.height || 375}`" accept="image/*"/>
                <base-input name="URL" label="URL" v-model="link" :rules="{ custom_required: true, custom_url: true }"
                    placeholder="URL" label-required>
                </base-input>
                <base-select name="patient_type" label="Choose Patient Type" v-model="patient_type" :options="patientTypes"
                    :rules="{ custom_required: true }" placeholder="Patient type" label-required collapse-tags multiple custom-multiple />
                <base-select name="stage" label="Choose Stage" v-model="stage" :options="stagesOptions"
                    :rules="{ custom_required: false }" placeholder="Stage" collapse-tags multiple custom-multiple />
                <div class="text-right mt-5">
                    <base-button @click="handleClose" type="gray">Cancel</base-button>
                    <base-button native-type="submit" type="bari" :disabled="loader">{{ getButtonName }}</base-button>
                </div>
            </form>
        </validation-observer>
    </el-dialog>
</template>
  
<script>
import FileInput from '@/components/Inputs/FileInput.vue';
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import patientConstants from '@/constants/patientConstants';

export default {
    props: ['visible', 'index', 'title', 'adSet', 'patientTypes', 'formType', 'bannerAd'],
    mixins:[generateErrorMessageMixin],
    data() {
        return {
            is_active: false,
            image: null,
            link: '',
            patient_type: [],
            stage: [],
            loader: false,
            adSetInfo: {},
            stagesOptions: patientConstants.stages.filter((el) => el.value )
        };
    },
    mounted() {

    },
    computed:{
        getButtonName(){
            return this.formType == 'store' ? 'Submit' : 'Update';
        },
        getImageRules(){
            return {
                custom_required: this.formType == 'store', extensions:['jpg','jpeg','png'],min_dimensions:[this.adSetInfo?.resolution?.width || 1000,this.adSetInfo?.resolution?.height || 375,this.adSetInfo?.resolution?.ratio || 2.7],
            }
        }
    },
    watch: {
        'bannerAd': function (newValue) {
            this.is_active = newValue?.is_active || false;
            if(newValue?.patient_type){
                this.patient_type = newValue.patient_type?.split(',');
            }else{
                this.patient_type = [];
            }
            if(newValue?.stage){
                this.stage = newValue.stage?.split(',');
            }else{
                this.stage = [];
            }
            this.link = newValue?.link || '';
        },
        'adSet': function (newValue) {
            this.adSetInfo = newValue;
        }
    },
    methods: {
        handleClose() {
            let validation = this.$refs.formValidator;
            this.image = null;
            this.$refs.image_input.clearFile();
            this.patient_type = '';
            this.stage = '';
            this.link = '';
            this.$refs.form.reset();
            validation.reset();
            this.$emit('closeDialog');
        },
        async onSubmit() {
            let validation = this.$refs.formValidator;
            this.loader = true;
            try {
                let fd = new FormData();
                fd.append('is_active', this.is_active ? 1 : 0);
                fd.append('image', this.image);
                fd.append('url', this.link);
                fd.append('patient_type', this.patient_type);
                fd.append('stage', this.stage);

                let response = {};
                if (this.formType == 'store') {
                    response = await this.$store.dispatch('AdsModule/_storeBannerAd',{fd,adSetId:this.adSetInfo.id});
                } else if (this.formType == 'update') {
                    response = await this.$store.dispatch('AdsModule/_updateBannerAd',{fd,id:this.bannerAd.id});
                }
                let { data: { data, message } } = response;
                if (this.formType == 'store') {
                    this.$emit('storeBannerAd', data);
                } else if (this.formType == 'update') {
                    this.$emit('updateBannerAd', { data, index: this.index });
                }
                this.$notify.success({
                    title: 'Banner Ad',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'Banner Ad',validation);
            }
            this.loader = false;
        },
        bannerImageSelect(files){
            this.image = files[0]
        }
    },
    components: { FileInput }
};
</script>
  
<style scoped lang="scss">
.status-text {
    font-weight: bolder;
}
</style>
  