<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2 ad-set-show" v-loading="loaders.fetching">
    <back-button class="ml-4" />
    <div class="ml-2 d-flex justify-content-between">
      <div>
        <p class="main-heading text-capitalize">{{ adSet.name ? `View ${adSet.name}` : "" }}</p>
        <p class="sub-heading"><span>Ad Set Id: </span>{{ adSet.code || "" }}</p>
      </div>
      <div>
        <base-button type="dark-blue" @click="add">Add New Ad</base-button>
      </div>
    </div>
    <div class="text-center cardStyle mt-4 pt-4" v-if="adSet && adSet.banner_ads && adSet.banner_ads.length == 0">
      <p class="font-weight-bold">No Ads Found</p>
    </div>
    <banner-ad v-else v-for="(item, index) in adSet.banner_ads" :key="index" :banner-ad="item"
      @editBannerAd="editBannerAd(item, index)" @deleteBannerAd="deleteBannerAd(item, index)"
      @resetStats="resetStats(item, index)" @toggleStatus="toggleStatus(item, index)" :patientTypes="patientTypes" />
    <add-edit-dialog :title="dialogTitle" :visible="showDialog" @closeDialog="closeDialog" :patientTypes="patientTypes"
      :formType="dialogFormType" :banner-ad="bannerAd" :index="bannerAdIndex" :ad-set="adSet"
      @storeBannerAd="storeBannerAd" @updateBannerAd="updateBannerAd" />
    <div class="modals">
      <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" :removeId="remove.id"
        @onRemove="removeBannerAd" />
      <confirm-action :loader="loaders.confirmModal" confirm-id="-1" @onConfirm="confirmedReset"></confirm-action>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import BannerAd from "@/views/Components/SuperAdmin/BannerAds/Card";
import AddEditDialog from "@/views/Components/SuperAdmin/BannerAds/AddEditDialog";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import ConfirmAction from "@/components/Modals/ConfirmAction";
import patientType from "@/mixins/patientTypeMixin";
export default {
  components: {
    BackButton,
    BannerAd,
    AddEditDialog,
    RemoveModal,
    ConfirmAction
  },
  mixins:[patientType],
  data() {
    return {
      loaders: {
        fetching: false,
        removeModal: false,
        confirmModal: false,
      },
      reset: {
        index: -1,
        id: ''
      },
      remove: {
        modalText: '',
        id: ''
      },
      adSet: {},
      showDialog: false,
      dialogTitle: '',
      dialogFormType: '',
      bannerAd: {},
      bannerAdIndex: -1
    };
  },
  mounted() {
    this.getAdSet();
  },
  methods: {
    closeDialog() {
      this.bannerAd = {}
      this.bannerAdIndex = -1;
      this.showDialog = false;
    },
    changePage(e) {
    },
    editAdSet(adSet, index) {

    },
    updateAdSet(data) {
    },
    async getAdSet() {
      this.loaders.fetching = true;
      try {
        let id = this.$route.params.id;
        let response = await this.$store.dispatch('AdsModule/_getAdSetById',{id});
        let {
          data: { data },
        } = response;
        this.adSet = data;
      } catch (error) {
        if (error?.response?.status == 404) {
          this.$router.push("/not-found")
        }
      }
      this.loaders.fetching = false;
    },

    add() {
      this.dialogTitle = `Add ${this.adSet.name}`;
      this.dialogFormType = 'store';
      this.showDialog = true;
    },
    deleteBannerAd(item) {
      this.$store.commit("showRemoveModal");
      this.remove.modalText = 'You want to remove this banner ad.';
      this.remove.id = item.id;
    },
    async removeBannerAd() {
      this.loaders.removeModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_deleteBannerAd',{id:this.remove.id});
        let {
          data: { message },
        } = response;
        this.$notify.success({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.$store.commit("hideRemoveModal");
        await this.getAdSet();
      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.removeModal = false;
    },
    resetStats(data, index) {
      this.$store.commit("showConfirmModal");
      this.reset.index = index;
      this.reset.id = data.id;
    },
    async confirmedReset() {
      this.loaders.confirmModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_resetStatsBannerAd',{id:this.reset.id});
        let {
          data: { message, data },
        } = response;
        this.$notify.success({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.$set(this.adSet.banner_ads, this.reset.index, data);
        this.$store.commit("hideConfirmModal");

      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.confirmModal = false;
    },
    async toggleStatus(item, index) {
      this.loaders.confirmModal = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_toggleStatusBannerAd',{id:item.id});

        let {
          data: { message, data },
        } = response;
        this.$notify.success({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
        this.$set(this.adSet.banner_ads, index, data);
        this.$store.commit("hideConfirmModal");

      } catch (error) {
        let message = error?.response?.data?.message || "Error";
        this.$notify.error({
          title: 'Banner Ad',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }
      this.loaders.confirmModal = false;
    },
    editBannerAd(bannerAd, index) {
      this.dialogTitle = `Edit ${this.adSet.name}`;
      this.dialogFormType = 'update';
      this.bannerAd = bannerAd;
      this.bannerAdIndex = index;
      this.showDialog = true;
    },
    storeBannerAd(data) {
      this.adSet.banner_ads = [data, ...this.adSet.banner_ads];
    },
    updateBannerAd(data) {
      this.$set(this.adSet.banner_ads, data.index, data.data);

    }
  },
};
</script>
<style scoped lang="scss"></style>
