<template>
    <div class="mt-4 mb-4 cardStyle p-4 banner-ad">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start align-items-center status mr-3">
                <p class="my-0 mr-4 ">Active/Inactive</p>
                <base-switch class="status-toggle super-admin" onText="" offText="" ref="templateSwitcher" :value="bannerAd.is_active"
                    @input="$emit('toggleStatus')">
                </base-switch>
            </div>
            <div>
                <base-button type="dark-blue" outline class="py-2 px-3 m-1" @click="$emit('editBannerAd')"> <i
                        class="fa fa-pen"></i> Edit</base-button>
                <base-button type="danger" outline class="py-2 px-3 m-1" @click="$emit('deleteBannerAd')"> <i
                        class="fa fa-trash"></i> Delete</base-button>
            </div>
        </div>
        <div class="row content mt-3">
            <div class="col-12 col-md-6">
                <div class="sub-content">
                    <p class="sub-heading">Ad Image</p>
                    <div class="data text-center">
                        <el-image :src="bannerAd.image_url" lazy/>
                    </div>
                </div>
                <div class="sub-content">
                    <p class="sub-heading">URL</p>
                    <div class="data d-flex justify-content-between align-items-center">
                        <p class="url">
                            <a class="text-dark" target="_blank" :href="bannerAd.link">{{ bannerAd.link }}</a>
                        </p>
                        <el-tooltip class="item" effect="dark" :content="copyToolTip" placement="top-start">
                            <div class="copy" v-html="copySvg" @click="copyToClipboard(bannerAd.link)" @mouseleave="resetToolTip"></div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="sub-content">
                    <p class="sub-heading">Patient Type</p>
                    <div class="data">
                        <template v-if="patientType.length">
                            <el-tag
                                v-for="item in patientType"
                                :key="item"
                                type="info"
                                effect="dark" class="mr-1" color="#2f597a">
                                {{ item }}
                            </el-tag>
                        </template>
                        <span v-else>N/A</span>
                    </div>
                </div>
                <div class="sub-content">
                    <p class="sub-heading">Stage</p>
                    <div class="data">
                        <template v-if="stages.length">
                            <el-tag
                                v-for="item in stages"
                                :key="item"
                                type="info"
                                effect="dark" class="mr-1" color="#2f597a">
                                {{ item }}
                            </el-tag>
                        </template>
                        <span v-else>N/A</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="sub-content h-100">
                    <p class="sub-heading">Status</p>
                    <div class="data second-column">
                        <div class="row p-3">
                            <div class="col-6 mb-1">
                                <span class="stats-heading">Views</span>
                                <p class="stats">{{ bannerAd.views }}</p>
                            </div>
                            <div class="col-6 mb-4">
                                <span class="stats-heading">Click Through</span>
                                <p class="stats">{{ isNaN((parseFloat(bannerAd.clicks) / parseFloat(bannerAd.views)) * 100) ? 0 : ((bannerAd.clicks
                                    /
                                    bannerAd.views) * 100).toFixed(3) }} %</p>
                            </div>
                            <div class="col-6 mb-4">
                                <span class="stats-heading">Clicks</span>
                                <p class="stats">{{ bannerAd.clicks }}</p>
                            </div>
                            <div class="col-6 mb-4">
                                <span class="stats-heading">Unique Clicks</span>
                                <p class="stats">{{ bannerAd.unique_clicks }}</p>
                            </div>
                            <div class="col-6">
                                <span class="stats-heading">Unique Views</span>
                                <p class="stats">{{ bannerAd.unique_views }}</p>
                            </div>
                        </div>
                        <div class="reset" @click="$emit('resetStats')">
                            <div v-html="refreshSvg" class="reset-button"></div>
                            <p>Reset</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import coptToClipboard from '@/mixins/coptToClipboardMixin';
import patientConstants from '@/constants/patientConstants';

export default {
    props: {
        bannerAd: {
            type: Object
        },
        patientTypes:{ 
            type: Array
        }
    },
    mixins:[coptToClipboard],
    components: {
    },
    data() {
        return {
            refreshSvg: `<svg id="refresh" xmlns="http://www.w3.org/2000/svg" width="17.42" height="17.421" viewBox="0 0 17.42 17.421">
  <g id="Group_12872" data-name="Group 12872" transform="translate(0.961 0)">
    <g id="Group_12871" data-name="Group 12871" transform="translate(0 0)">
      <path id="Path_11114" data-name="Path 11114" d="M44.078,2.4a.68.68,0,0,0-.739.616l-.09.989a8.59,8.59,0,0,0-14.937.716.68.68,0,0,0,1.212.619,7.234,7.234,0,0,1,12.586-.6l-.97-.693a.68.68,0,0,0-.791,1.108l2.942,2.1a.681.681,0,0,0,1.073-.492l.33-3.625A.68.68,0,0,0,44.078,2.4Z" transform="translate(-28.237)" fill="currentColor"/>
    </g>
  </g>
  <g id="Group_12874" data-name="Group 12874" transform="translate(0 10.034)">
    <g id="Group_12873" data-name="Group 12873">
      <path id="Path_11115" data-name="Path 11115" d="M16.09,296.659a.681.681,0,0,0-.916.3,7.234,7.234,0,0,1-12.586.6l.97.693a.68.68,0,1,0,.791-1.107l-2.942-2.1a.681.681,0,0,0-1.073.492L0,299.156a.68.68,0,1,0,1.355.123l.09-.989a8.59,8.59,0,0,0,14.937-.716A.681.681,0,0,0,16.09,296.659Z" transform="translate(-0.001 -294.913)" fill="currentColor"/>
    </g>
  </g>
</svg>`
        };
    },
    methods: {

    },
    computed: {
        patientType() {
            let bannerAdPatientType = this.bannerAd.patient_type?.split(',') || [];
            return bannerAdPatientType.sort().map((el) => {
                return this.patientTypes.find((e, index) => e.value == el)?.label || ''
            }).filter(el => el)
        },
        stages() {
            return this.bannerAd.stage ? this.bannerAd.stage.split(',').map((el) => {
                return patientConstants.stages.find(item => item.value == el)?.label || ''
            }).filter(el => el).sort() : [];
        }
    }
};
</script>
<style scoped lang="scss"></style>
  